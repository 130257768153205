import {API_ENDPOINT} from "../../../constants";

/**
 * Convert a list of landmarks to an array of keypoints.
 * @param {Array} mpLandmarkList - List of landmarks.
 * @returns {Array} - Array of keypoints.
 */
function landmarkToArray(mpLandmarkList) {
    const keypoints = [];
    for (const landmark of mpLandmarkList) {
        keypoints.push(landmark.x, landmark.y, landmark.z);
    }
    return keypoints;
}

/**
 * Extract pose, left hand, and right hand landmarks from the results.
 * @param {any} results - Landmark results object.
 * @returns {Array} - Array containing pose, left hand, and right hand landmarks.
 */
function extractLandmarks(results: any) {

    const pose = new Array(99).fill(0);
    if (results.poseLandmarks) {
        pose.splice(0, 99, ...landmarkToArray(results.poseLandmarks));
    }

    const leftHand = new Array(63).fill(0);
    if (results.leftHandLandmarks) {
        leftHand.splice(0, 63, ...landmarkToArray(results.leftHandLandmarks));
    }

    const rightHand = new Array(63).fill(0);
    if (results.rightHandLandmarks) {
        rightHand.splice(0, 63, ...landmarkToArray(results.rightHandLandmarks));
    }

    return [pose, leftHand, rightHand];
}

/**
 * Encode a string into base64 format.
 * @param {string} input - Input string to encode.
 * @returns {string} - Base64-encoded string.
 */
function base64Encode(input: string): string {
    try {
        return btoa(unescape(encodeURIComponent(input)));
    } catch (e) {
        console.error('Failed to encode base64:', e);
        return '';
    }
}

/**
 * Prepare an authorization header for API requests.
 * @param {string} username - Username for authentication.
 * @param {string} password - Password for authentication.
 * @returns {string} - Authorization header string.
 */
function prepareAuthHeader(username: string, password: string): string {
    const authHeader = base64Encode(`${username}:${password}`);
    return `Basic ${authHeader}`;
}

/**
 * Predict values using an API endpoint.
 * @param {any} recordedResults - Recorded results for prediction.
 * @returns {Promise} - Promise that resolves with the prediction results.
 */
async function predictValuesApi(recordedResults: any) {
    const headers = {
        "Authorization": prepareAuthHeader("dillo", "dillo"),
        'Content-Type': 'application/json',
        'Accept': 'text/plain',
        'Access-Control-Allow-Origin': '*'
    };

    const signs = recordedResults.map((results: any) => {
        const [pose, leftHand, rightHand] = extractLandmarks(results);
        return {
            'pose': pose,
            'left_hand': leftHand,
            'right_hand': rightHand,
        };
    });

    const payload = {
        "keypoints": signs,
    };

    try {
        const response = await fetch(`${API_ENDPOINT}/predict/word`, {
            method: 'POST',
            headers,
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}

/**
 * Convert an HTML image element to a Blob object.
 * @param {HTMLImageElement} image - HTML image element to convert.
 * @returns {Promise<Blob>} - Promise that resolves with the Blob object.
 */
function imageToBlob(image: HTMLImageElement, quality): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        if (context) {
            context.drawImage(image, 0, 0, image.width, image.height);
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Failed to convert image to blob'));
                }
            }, 'image/jpeg', quality);
        } else {
            reject(new Error('Failed to get canvas context'));
        }
    });
}

/**
 * Predict alphabet using an API endpoint.
 * @param {any} recordedResults - Recorded results for alphabet prediction.
 * @returns {Promise} - Promise that resolves with the alphabet prediction results.
 */
async function predictAlphabetApi(recordedResults: any) {
    const headers = {
        "Authorization": prepareAuthHeader("dillo", "dillo"),
        'Accept': 'text/plain',
        'Access-Control-Allow-Origin': '*'
    };

    const formData = new FormData();

    for (let index = 0; index < recordedResults.length; index++) {
        try {
            const blob = await imageToBlob(recordedResults[index].image, 0.6);
            formData.append('files', blob, `frame_${index}.jpeg`);
        } catch (error) {
            console.error('Failed to convert image to blob', error);
        }
    }

    try {
        const response = await fetch(`${API_ENDPOINT}/predict/alphabet`, {
            method: 'POST',
            headers,
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}

export {predictAlphabetApi}

/**
 * Store recorded results using an API endpoint.
 * @param {any} recordedResults - Recorded results to store.
 * @param {boolean} alphabet - Boolean indicating whether alphabet mode is active.
 * @param {string} word - The word associated with the recorded results.
 * @returns {Promise} - Promise that resolves with the result of the storage operation.
 */
async function storeApi(recordedResults: any, alphabet: boolean, word) {
    const headers = {
        "Authorization": prepareAuthHeader("dillo", "dillo"),
        'Access-Control-Allow-Origin': '*',
        "Accept": "application/json"
    };

    const formData = new FormData();

    for (let index = 0; index < recordedResults.length; index++) {
        try {
            const blob = await imageToBlob(recordedResults[index], 0.9);
            formData.append('files', blob, `frame_${localStorage.getItem('userEmail')}_${index}.jpeg`);
        } catch (error) {
            console.error('Failed to convert image to blob', error);
        }
    }

    // Adding the metadata payload to the formData
    let payload = {
        metadata: {
            word: word,
            alphabet: alphabet
        }
    }
    formData.append('payload', JSON.stringify(payload));

    try {
        const response = await fetch(`${API_ENDPOINT}/store/`, {
            method: 'POST',
            headers,
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.log(errorData)
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}

export {storeApi}

/**
 * Get available words from an API endpoint.
 * @returns {Promise} - Promise that resolves with the list of available words.
 */
async function getAvailableWords() {
    const headers = {
        "Authorization": prepareAuthHeader("dillo", "dillo"),
        'Access-Control-Allow-Origin': '*',
        "Accept": "application/json"
    };

    try {
        const response = await fetch(`${API_ENDPOINT}/words`, {
            method: 'GET',
            headers,
        });

        return await response.json();
    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}

export {getAvailableWords}


/**
 * Client to interact with OpenAI's GPT API using the official OpenAI Python client and chat-based completions.
 * @returns {string} - str the string from the OPEN API
 */
async function generateSentence(words) {
    const headers = {
        "Authorization": prepareAuthHeader("dillo", "dillo"),
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    // Ensure words is an array
    if (!Array.isArray(words)) {
        words = [words];
    }

    let payload = {
        sentence: words
    };

    console.log(payload)
    try {
        const response = await fetch(`${API_ENDPOINT}/predict/sentence/`, {
                method: 'POST',
                headers,
                body: JSON.stringify(payload)
            }
        );

        const result = await response.json();
        return result.sentence;
    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}

export {generateSentence}


/**
 * Validate tokens with Pocketbase
 * @returns {Promise} - Promise that resolves with the list of available words.
 */
export async function validateToken(token) {
    const headers = {
        "Authorization": token,
        'Access-Control-Allow-Origin': '*',
        "Accept": "application/json"
    };

    try {
        const response = await fetch(`https://auth.dillo.ar/api/collections/users/auth-refresh`, {
            method: 'post',
            headers,
        });

        return await response.json();

    } catch (error) {
        console.error('Failed to send request because of:', error);
        throw new Error(String(error));
    }
}



export default predictValuesApi;