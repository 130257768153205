const API_ENDPOINT = 'https://api.dillo.ar';

export { API_ENDPOINT };

const FRAMES_LENGTH_FOR_TRAINING = 20

export {FRAMES_LENGTH_FOR_TRAINING}

const FRAMES_LENGTH_FOR_PREDICT = 35

export {FRAMES_LENGTH_FOR_PREDICT}

const FRAMES_LENGTH_FOR_PREDICT_ALPHABET = 10

export {FRAMES_LENGTH_FOR_PREDICT_ALPHABET}

const MODAL_PROBABILITY_THRESHOLD = 80;

export {MODAL_PROBABILITY_THRESHOLD}
