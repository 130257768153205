import {useEffect, useRef} from 'react';
import {Camera} from '@mediapipe/camera_utils';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {Hands, HAND_CONNECTIONS} from '@mediapipe/hands';
import {
    FRAMES_LENGTH_FOR_PREDICT_ALPHABET,
    FRAMES_LENGTH_FOR_TRAINING
} from "../../../../constants";

let maxVideoWidth = 1280;
let maxVideoHeight = 720;

if (window.innerWidth < 720) {
    maxVideoWidth = 960;
    maxVideoHeight = 540;
}


let recordedResults: any[] = [];
let lastPredictedWord = '';


function useTraining(
    {
        isDialogOpen,
        alphabetDetection,
        selectedLetterRef,
        selectedWordRef,
        setSnackbar,
        setPendingResults,
        setModalOpen,
        isPlayingRef
    }
) {

    async function initCamara() {
        if (!videoElement.current || !hands.current) return;

        camera.current?.stop();  // Ensure the camera is stopped before reinitializing

        camera.current = new Camera(videoElement.current, {
            onFrame: async () => {
                await hands.current!.send({image: videoElement.current!});
            },
            width: maxVideoWidth,
            height: maxVideoHeight,
        });
        camera.current.start();
    }

    useEffect(() => {
        initCamara();
        loadHandsDetection();

        return () => {
            camera.current?.stop();
            hands.current?.close();
        };
    }, []);

    const videoElement = useRef<HTMLVideoElement | null>(null);
    const hands = useRef<Hands | null>(null);
    const camera = useRef<Camera | null>(null);
    const canvasEl = useRef<HTMLCanvasElement | null>(null);
    const isProcessingRef = useRef(false);

    const getProgress = () => {
        return recordedResults.length;
    };

    const resetProgress = () => {
        recordedResults = [];
    };
    const alphabetDetectionRef = useRef(alphabetDetection);

    useEffect(() => {
        alphabetDetectionRef.current = alphabetDetection;
    }, [alphabetDetection]);

    async function processLandmarks(results: any) {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;
        const MIN_NUM_LANDMARKS = 5; // Define a minimum number of landmarks to ensure valid detection

        const leftHandHasLandmarks = results.leftHandLandmarks && results.leftHandLandmarks.length >= MIN_NUM_LANDMARKS;
        const rightHandHasLandmarks = results.rightHandLandmarks && results.rightHandLandmarks.length >= MIN_NUM_LANDMARKS;

        const hasLandmarks = leftHandHasLandmarks || rightHandHasLandmarks;
        if (hasLandmarks) {
            const isAlphabetDetectionOn = alphabetDetectionRef.current;

            try {
                let lengthVerify = isAlphabetDetectionOn ? FRAMES_LENGTH_FOR_PREDICT_ALPHABET : FRAMES_LENGTH_FOR_TRAINING;
                if (recordedResults.length < lengthVerify && !isDialogOpen.current) {
                    const canvasClone = document.createElement('canvas');
                    canvasClone.width = results.image.width;
                    canvasClone.height = results.image.height;
                    const ctxClone = canvasClone.getContext('2d');
                    ctxClone.drawImage(results.image, 0, 0);

                    recordedResults.push(canvasClone);
                } else {

                    let wordStore = isAlphabetDetectionOn ? selectedLetterRef.current : selectedWordRef.current
                    if (wordStore == null) {
                        setSnackbar({open: true, message: "Por favor selecciona una palabra/letra", severity: "error"});
                        recordedResults = [];
                    } else {
                        setPendingResults({recordedResults, isAlphabetDetectionOn, wordStore});
                        isDialogOpen.current = true
                        setModalOpen(true); // Open the confirmation modal
                        recordedResults = [];

                        lastPredictedWord = selectedLetterRef.current;
                    }
                }
            } catch (e) {
                console.error('Prediction failed', e);
                setSnackbar({open: true, message: "No se ha podido guardar", severity: "error"});
                recordedResults = [];
            }
        }
        console.log(recordedResults.length)
        isProcessingRef.current = false;
    }


    async function onResults(results: any) {
        if (canvasEl.current) {
            const ctx = canvasEl.current.getContext('2d');
            if (!ctx) return;

            ctx.save();
            ctx.clearRect(0, 0, canvasEl.current.width, canvasEl.current.height);

            if (results.multiHandLandmarks && results.multiHandedness) {

                for (let index = 0; index < results.multiHandLandmarks.length; index++) {
                    const classification = results.multiHandedness[index];
                    const isRightHand = classification.label === 'Right';
                    const landmarks = results.multiHandLandmarks[index];
                    processAndDrawHandLandmarks(landmarks, ctx);
                    if (isRightHand) {
                        results.rightHandLandmarks = landmarks
                    } else {
                        results.leftHandLandmarks = landmarks
                    }

                }

            }

            if (!isDialogOpen.current && !isProcessingRef.current && !isPlayingRef.current) {
                await processLandmarks(results)
            }

            ctx.restore();
        }
    }

    const loadHandsDetection = () => {
        hands.current = new Hands({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
            },
        });

        // @ts-ignore
        hands.current.setOptions({
            maxNumHands: 2,
            modelComplexity: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
            // @ts-ignore
            useCpuInference: false,
            staticImageMode: true,
            delegate: "gpu",
        });
        // @ts-ignore
        hands.current.onResults(onResults);
    };

    useEffect(() => {
        async function initCamara() {
            if (!videoElement.current || !hands.current) return;

            camera.current = new Camera(videoElement.current, {
                onFrame: async () => {
                    await hands.current!.send({image: videoElement.current!});
                },
                width: maxVideoWidth,
                height: maxVideoHeight,
            });
            camera.current.start();

            console.log(camera.current)
            if (camera.current) {
                const currentCamera = camera.current as any;
                maxVideoWidth = currentCamera.h.width;
                maxVideoHeight = currentCamera.h.height;
            }


        }

        initCamara().then(r => loadHandsDetection());
    }, []);

    return {maxVideoHeight, maxVideoWidth, canvasEl, videoElement, getProgress, resetProgress};
}

function processAndDrawHandLandmarks(landmarks: any, ctx: CanvasRenderingContext2D) {
    if (window.innerWidth >= 720) {
        drawConnectors(ctx, landmarks, HAND_CONNECTIONS, {
            color: '#00ffff',
            lineWidth: 1,
        });
        drawLandmarks(ctx, landmarks, {
            color: '#ffff29',
            lineWidth: 1,
        });
    }
}

export default useTraining;
